var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { "padding-top": "30px" } },
        [
          _c(
            "el-steps",
            { attrs: { active: _vm.active, "align-center": "" } },
            [
              _c("el-step", { attrs: { title: "填写商家信息" } }),
              _c("el-step", { attrs: { title: "填写店铺信息" } }),
              _c("el-step", { attrs: { title: "填写收款账户信息" } }),
              _c("el-step", { attrs: { title: "开始使用" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.active === 1
        ? _c("shopSettleInfo", {
            on: {
              submit: function ($event) {
                _vm.active++
              },
            },
          })
        : _c("storeDetail", { attrs: { "page-type": "newpage" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }