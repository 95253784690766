<template>
  <div>
    <div style="padding-top: 30px;">
      <el-steps
        :active="active"
        align-center
      >
        <el-step title="填写商家信息"></el-step>
        <el-step title="填写店铺信息"></el-step>
        <el-step title="填写收款账户信息"></el-step>
        <el-step title="开始使用"></el-step>
      </el-steps>
    </div>
    <shopSettleInfo v-if="active === 1" @submit="active++" />
    <storeDetail v-else page-type="newpage" />
  </div>
</template>
<script>
import storeDetail from './storeDetail.vue'
import shopSettleInfo from '@/views/shopSettle/info.vue'
export default {
  name: 'StoreAudit',
  components: {
    storeDetail, shopSettleInfo
  },
  data() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const active = this.userInfo.isNeedMend === 1 ? 1 : 2;
    return {
      active
    }
  },
  methods: {
    createShops() {
      if (this.userInfo.defaultStoreId === 0) this.active = 2;
      else if (this.userInfo.financialStatus === 0) this.$router.push('/supplybankAudit?type=add');
      else this.$router.replace('/nb/setting/store/list');
    }
  }
}
</script>
